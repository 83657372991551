import React, { ReactNode } from "react";
import { Box } from "theme-ui";
import { useSiteMetadata } from "../hooks/use-site-metadata";
import { Header } from "./header";
import { Helmet } from "react-helmet";

export const Layout = ({ children }: { children: ReactNode }) => {
  const { title, description } = useSiteMetadata();
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
      />
      <Header siteTitle={title} siteDescription={description} />
      <Box
        as="div"
        sx={{
          margin: "0 auto",
          maxWidth: "min(1400px, 80%)",
          padding: "0 1.0875rem 1.45rem",
        }}
      >
        <Box as="main">{children}</Box>
      </Box>
    </>
  );
};
