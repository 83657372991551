import { deep, base } from "@theme-ui/presets";
import nightOwl from "@theme-ui/prism/presets/night-owl.json";

const theme = {
    ...base,
    colors: {
        ...base.colors,
        modes: {
            dark: {
                ...deep.colors,
            },
        },
    },

    styles: {
        ...base.styles,
        modes: {
            dark: {
                ...deep.styles,
            }
        },
        code: {
            ...nightOwl,
        },
        p: {
            fontFamily: "body",
            fontWeight: "body",
            lineHeight: "body",
            fontSize: 3,
        },
    },
};

export default theme;
